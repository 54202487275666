<template>
     <section id="dashboard-ecommerce">
        <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
            <b-row class="match-height">
                <b-col :xl="filter.idEmpresa && filter.precioEmpresaId ? 12 : 6" md="12">
                    <b-card-actions ref="cardFirstConnection" :noActions="true">
                        <b-row>
                            <b-col md="12" :xl="filter.idEmpresa && filter.precioEmpresaId ? 3 : 6">
                                <b-form-group label="Conexión" label-for="idEmpresa" label-class="font-weight-bold">
                                    <v-select id="idEmpresa"
                                        disabled                                        
                                        class="select-size-sm"
                                        v-model="filter.idEmpresa"
                                        :options="connections"
                                        :reduce="option => option.idEmpresa" 
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false"
                                        @input="selectConnection">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col md="12" :xl="filter.idEmpresa && filter.precioEmpresaId ? 3 : 6">
                                <b-form-group label="Lista Precios" label-for="precio_Empresa_Id" label-class="font-weight-bold">
                                    <v-select id="precio_Empresa_Id"
                                        class="select-size-sm"
                                        v-model="filter.precioEmpresaId"
                                        :options="pricesList"
                                        :reduce="option => option.precio_Empresa_Id" 
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false"
                                        @input="selectPriceList">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col v-if="filter.idEmpresa && filter.precioEmpresaId" md="12" xl="3">
                                <b-form-group label="Conexión Secundaria" label-for="idEmpresa2" label-class="font-weight-bold">
                                    <v-select id="idEmpresa2"  
                                        disabled                                      
                                        class="select-size-sm"
                                        v-model="filter2.idEmpresa"
                                        :options="connections.filter(item => item.idEmpresa !== filter.idEmpresa)"
                                        :reduce="option => option.idEmpresa" 
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false"
                                        @input="selectConnection2">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col v-if="filter2.idEmpresa && filter.precioEmpresaId" md="12" xl="3">
                                <b-form-group label="Lista Precios Secundaria" label-for="precio_Empresa_Id2" label-class="font-weight-bold">
                                    <v-select id="precio_Empresa_Id2"
                                        class="select-size-sm"
                                        v-model="filter2.precioEmpresaId"
                                        :options="pricesList2"
                                        :reduce="option => option.precio_Empresa_Id" 
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false"
                                        @input="selectPriceList2">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col v-if="filter.idEmpresa && filter.precioEmpresaId && filter2.idEmpresa && filter2.precioEmpresaId" md="3">
                                <b-button size="sm" variant="primary" @click="loadArticles">Buscar</b-button>
                                <b-button class="ml-1" v-if="articles.length && articles2.length" size="sm" @click="exportToExcel" variant="secondary">
                                    <feather-icon icon="DownloadIcon" />
                                    Exportar a excel
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-actions>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-card-actions ref="cardArticles" :noActions="true" :isTable="true">
                        <div ref="tableContainer" class="mb-0 table-responsive" :class="articles.length > 0 ? 'b-table-sticky-header' : ''" @scroll="handleScroll">
                            <table ref="refListTable" class="table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single table-small text-small small">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('articulo_Id')">Articulo ID</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('clave_Articulo')">Clave Articulo</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('nombre')">Articulo</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('precio')" class="text-nowrap text-center">Lista Precio</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('ultimo_Costo')">Últ. Costo</th>                                        
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('articulo_Id2')">Articulo ID</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('clave_Articulo2')">Clave Articulo</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('nombre2')">Articulo</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('precio2')" class="text-nowrap text-center">Lista Precio</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('ultimo_Costo2')" class="text-center">Últ. Costo</th>
                                    </tr>
                                </thead>
                                <tbody v-if="visibleItems.length > 0">
                                    <tr v-for="(articulo, index) in visibleItems" :key="index" @click="selectRow(articulo, index)"
                                        :class="{'selected-row': isSelected(index),}">
                                        <td>
                                            <b-form-checkbox v-model="articulo.selected" :name="`selectedItems-${index}`" @change="toggleRowSelection(articulo)" />
                                        </td>
                                        <td style="font-size: 10px;" class="td-clave-articulo">{{ articulo.articulo_Id }}</td>
                                        <td style="font-size: 10px;" class="td-clave-articulo">{{ articulo.clave_Articulo }}</td>
                                        <td style="font-size: 10px;" class="td-articulo text-wrap">{{ articulo.nombre }}</td>
                                        <td style="font-size: 10px;" class="td-precio-lista text-center">{{ articulo.precio }}</td>
                                        <td style="font-size: 10px;" class="td-ultimo-costo">{{ articulo.ultimo_Costo }}</td>                                        
                                        <td style="font-size: 10px;;" class="td-clave-articulo">{{ articulo.articulo_Id2 }}</td>
                                        <td style="font-size: 10px;;" class="td-clave-articulo">{{ articulo.clave_Articulo2 }}</td>
                                        <td style="font-size: 10px;;" class="td-articulo text-wrap">{{ articulo.nombre2 }}</td>
                                        <td style="font-size: 10px;;" class="td-precio-lista text-center">{{ articulo.precio2 }}</td>
                                        <td style="font-size: 10px;;" class="text-center">{{ articulo.ultimo_Costo2 }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="10" class="text-center">
                                            <h6 class="mt-2">No hay registros</h6>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-card-actions>
                </b-col>
            </b-row>

            <b-row v-if="articles.length > 0 && articles2.length > 0 && visibleItems.length > 0">
                <b-col cols="12">
                    <div class="demo-inline-spacing float-right buy-now">
                        <b-button v-if="isSelectedRows" @click="showAlertToSyncSelected" size="sm" variant="primary">
                            <feather-icon icon="RefreshCcwIcon" size="16" />
                            Sincronizar Seleccionados
                        </b-button>
                        <b-button @click="showAlertToSyncAll" variant="danger">
                            <feather-icon icon="RefreshCcwIcon" size="16" />
                            Sincronizar Todos
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-overlay>
    </section>
</template>

<script>
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BCardFooter, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab,
} from 'bootstrap-vue'
import store from '@/store'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import vSelect from "vue-select";
import { RecycleScroller } from 'vue-virtual-scroller';
import * as XLSX from 'xlsx';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

import ArticlesProvider from '@/providers/Articles'
const ArticlesResource = new ArticlesProvider()

import PricesArticlesProvider from '@/providers/PricesArticles'
const PricesArticlesResource = new PricesArticlesProvider()

import ConnectionsProvider from '@/providers/Connections'
import parse from 'uuid/dist/parse';
const ConnectionsResource = new ConnectionsProvider()


export default {
    name: 'RelationPrices',
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BCardFooter,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BAvatar,
        BPagination,
        BFormSelect,
        BBadge,
        BCardSubTitle,
        BCardTitle,
        BTabs,
        BTab,

        //
        BCardActions,
        vSelect,
        RecycleScroller,
        FeatherIcon,
    },
    data() {
        return {        
            pricesList: [],        // Lista de precios
            pricesList2: [],        // Lista de precios de la conexión secundaria
            articles: [],          // Lista de artículos
            articles2: [],         // Lista de artículos de la conexión secundaria
            searchQuery: '',       // Consulta de búsqueda
            pageNumber: 1,         // Número de página actual
            pageSize: 1000,          // Tamaño de página
            loading: false,        // Indicador de carga
            items: [],             // Lista de artículos a vincular
            filter : {
                idEmpresa: null,
                precioEmpresaId: null,
            },

            filter2 : {
                idEmpresa: null,
                precioEmpresaId: null,
            },
            selectedRowIndex: -1,      // Índice seleccionado
            isActiveModal: false,      // Indicador de modal activo
            isLinked: false,
            relation: {},
            sortKey: '', // Clave para ordenar
            sortOrder: 1, // 1 para ascendente, -1 para descendente
            itemsPerPage: 500, // Número de artículos cargados por bloque
            currentPage: 1,
            visibleItems: [],
            claveArticulo: '',
            claveArticulo2: '',
            noRelationated: false,
            noRelationated2: false,
            onlyRelationated: true,
            onlyPriceDifference: true,
            selecteds: [],
            onlySelected: false,
        }
    },
    computed: {
        currentConnectionId() {
            return store.getters['auth/getConexionId']
        },
        connections() {
            return store.getters['auth/getConnections'] //.filter(item => item.idEmpresa !== this.currentConnectionId)
        },
        currentConnection() {
            return store.getters['auth/getConnections'].find(item => item.idEmpresa === this.filter.idEmpresa)
        },
        secondaryConnections() {
            return store.getters['auth/getConnections'].find(item => item.idEmpresa == this.filter2.idEmpresa)
        },
         // Computed property para unificar los artículos de ambas empresas       
        articulosCombinados() {
            let combined = [];  

            // Mapeo de artículos de la empresa 1
            this.articles.forEach(articulo => {
                let articuloCombinado = {                    
                    
                    articulo_Id: articulo.articulo_Id,
                    clave_Articulo: articulo.clave_Articulo,
                    nombre: articulo.nombre,
                    precio: articulo.precio,
                    rel_Articulo_Id: articulo.rel_Articulo_Id,
                    rel_Clave_Id: articulo.rel_Clave_Id,
                    rel_Clave_Id_Concretos: articulo.rel_Clave_Id_Concretos,
                    rel_Clave_Id_Matriz: articulo.rel_Clave_Id_Matriz,
                    rel_Clave_Id_Orlo: articulo.rel_Clave_Id_Orlo,
                    ultimo_Costo: articulo.ultimo_Costo,

                    articulo_Id2: '',
                    clave_Articulo2: '',
                    nombre2: '',
                    precio2: '',
                    rel_Articulo_Id2: '',
                    rel_Clave_Id2: '',
                    rel_Clave_Id_Concretos2: '',
                    rel_Clave_Id_Matriz2: '',
                    rel_Clave_Id_Orlo2: '',
                    ultimo_Costo2: '',
                };
                combined.push(articuloCombinado);
            });

       

            // Mapeo de artículos de la empresa 2
            this.articles2.forEach(articulo => {

                // Buscar si el artículo de empresa 2 ya existe en la lista combinada por su relación Concretos
                // let existente = combined.find(item => item.rel_Clave_Id_Concretos === articulo.rel_Clave_Id_Concretos);
                let existente = combined.find(item => {
                    return item.rel_Clave_Id_Concretos && articulo.rel_Clave_Id_Concretos &&
                        item.rel_Clave_Id_Concretos === articulo.rel_Clave_Id_Concretos;
                });

                if (existente) {

                    // Si existe, completamos los campos de la empresa 2
                    existente.articulo_Id2 = articulo.articulo_Id;
                    existente.clave_Articulo2 = articulo.clave_Articulo;
                    existente.nombre2 = articulo.nombre;
                    existente.precio2 = articulo.precio;
                    existente.rel_Articulo_Id2 = articulo.rel_Articulo_Id;
                    existente.rel_Clave_Id2 = articulo.rel_Clave_Id;
                    existente.rel_Clave_Id_Concretos2 = articulo.rel_Clave_Id_Concretos;
                    existente.rel_Clave_Id_Matriz2 = articulo.rel_Clave_Id_Matriz;
                    existente.rel_Clave_Id_Orlo2 = articulo.rel_Clave_Id_Orlo;
                    existente.ultimo_Costo2 = articulo.ultimo_Costo;
                } else {
                    
                    // Si no existe, lo agregamos como un nuevo elemento combinado
                    let articuloCombinado = {
                        articulo_Id: '',
                        clave_Articulo: '',
                        nombre: '',
                        precio: '',
                        rel_Articulo_Id: '',
                        rel_Clave_Id: '',
                        rel_Clave_Id_Concretos: '',
                        rel_Clave_Id_Matriz: '',
                        rel_Clave_Id_Orlo: '',
                        ultimo_Costo: '',
                        articulo_Id2: articulo.articulo_Id,                        
                        clave_Articulo2: articulo.clave_Articulo,
                        nombre2: articulo.nombre,
                        precio2: articulo.precio,
                        rel_Articulo_Id2: articulo.rel_Articulo_Id,
                        rel_Clave_Id2: articulo.rel_Clave_Id,
                        rel_Clave_Id_Concretos2: articulo.rel_Clave_Id_Concretos,
                        rel_Clave_Id_Matriz2: articulo.rel_Clave_Id_Matriz,
                        rel_Clave_Id_Orlo2: articulo.rel_Clave_Id_Orlo,
                        ultimo_Costo2: articulo.ultimo_Costo,
                    };
                    combined.push(articuloCombinado);
                }
            });
            
            if (this.onlyPriceDifference) {
                const newItems = combined.filter(item => {
                    // Comprobar si rel_Clave_Id y rel_Clave_Id2 son válidos (no null, vacíos, 0 o -1) y si son diferentes
                    const claveValida = item.rel_Clave_Id !== null && item.rel_Clave_Id !== '' && item.rel_Clave_Id !== 0 && item.rel_Clave_Id !== -1;
                    const claveValida2 = item.rel_Clave_Id2 !== null && item.rel_Clave_Id2 !== '' && item.rel_Clave_Id2 !== 0 && item.rel_Clave_Id2 !== -1;
                    
                    // Comprobar si las claves son diferentes, asegurando que ambas sean válidas
                    const claveDiferente = claveValida && claveValida2 && item.rel_Clave_Id !== item.rel_Clave_Id2;

                    // Convertir los precios a números para evitar problemas de comparación de strings
                    const precio1 = parseFloat(item.precio);
                    const precio2 = parseFloat(item.precio2);

                    // Comprobar si los precios son diferentes, si son nulos, vacíos o 0
                    const precioDiferente = isNaN(precio1) || isNaN(precio2) || precio1 !== precio2 || precio1 === 0 || precio2 === 0;

                    // Devolver verdadero si alguna de las condiciones es verdadera
                    return /*claveDiferente ||*/ precioDiferente;
                });

                return newItems;
            } else {
                return combined
            }
        },
        sortedArticulosCombinados() {
            let data = this.articulosCombinados.map(i => ({...i, selected: false})).slice().sort((a, b) => {
                if (!this.sortKey) return 0;
                
                const aValue = a[this.sortKey];
                const bValue = b[this.sortKey];

                // Si aValue es null y bValue no, ponemos aValue primero
                if (aValue === '' && bValue !== '') return -1;
                // Si bValue es null y aValue no, ponemos bValue primero
                if (bValue === '' && aValue !== '') return 1;

                // Si ambos son null, son iguales
                if (aValue === '' && bValue === '') return 0;

                // Orden normal cuando no hay null
                if (aValue < bValue) return -1 * this.sortOrder;
                if (aValue > bValue) return 1 * this.sortOrder;
                return 0;
            });

            // Aplicar los filtros
            // if (this.noRelationated) {
            //     return data.filter(a => a.rel_Clave_Id === -1);
            // } else if (this.noRelationated2) {
            //     return data.filter(a => a.rel_Clave_Id2 === -1);
            // }

            return data;
        },
        firstNumNoRelationed() {
            return this.articles.filter(a => a.rel_Clave_Id === -1).length
        },
        firstNumNoRelationed2() {
            return this.articles2.filter(a => a.rel_Clave_Id === -1).length
        },
        firstNumRelationated() {
            return this.articles.filter(a => a.rel_Clave_Id > 0).length
        },
        firstNumRelationated2() {
            return this.articles2.filter(a => a.rel_Clave_Id > 0).length
        },
        isSelectedRows() {
            return this.visibleItems.filter(a => a.selected === true).length > 0
        }
    },
    beforeDestroy() {
        const tableContainer = this.$refs.tableContainer;
        tableContainer.removeEventListener('scroll', this.handleScroll);
    },
    async mounted () {
        this.filter.idEmpresa = 1
        this.filter2.idEmpresa = 2
        this.$nextTick(async () => {
            await this.getPricesList(this.filter.idEmpresa)
            await this.getPricesList2(this.filter2.idEmpresa)
        })
    },
    methods: {
        showAlertToSyncSelected() {
            this.onlySelected = true
            this.showAlertToSync();
        },
        showAlertToSyncAll() {
            this.onlySelected = false
            this.showAlertToSync();
        },
        async exportToExcel() {
            this.loading = true
            this.currentPage = 1
            this.visibleItems = this.sortedArticulosCombinados    
            
            await this.$nextTick()

            const table = this.$refs.refListTable;
            const wb = XLSX.utils.table_to_book(table);
            XLSX.writeFile(wb, 'Articulos-Precios.xlsx');

            await this.$nextTick();

            const end = 1 * this.itemsPerPage;
            this.visibleItems = this.sortedArticulosCombinados.slice(0, end);

            this.loading = false
        },
        toggleRowSelection(item) {
            this.onlySelected = this.visibleItems.filter(a => a.selected === true).length > 0
        },
        showAlertToSync() {
            this.$swal({
                title: 'Sincronización de Precios',
                html: `
                    <p>Has detectado diferencias en los precios entre las dos conexiones de bases de datos.</p>
                    <p><strong>¿De cuál conexión deseas sincronizar los precios?</strong></p>
                    <p>Los precios seleccionados sobrescribirán los de la otra conexión. <br><strong>¡Esta acción no se puede deshacer!</strong></p>
                    <p>Elige una opción:</p>
                    <ul style="text-align: left;">
                        <li><strong>Conexión 1:</strong> Actualiza los precios en Conexión 2 con los de Conexión 1.</li>
                        <li><strong>Conexión 2:</strong> Actualiza los precios en Conexión 1 con los de Conexión 2.</li>
                    </ul>
                    <strong>Esto proceso puede demorar....</strong>
                `,
                icon: 'warning',
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonText: 'Conexión 1',
                denyButtonText: 'Conexión 2',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: "btn btn-primary",
                    denyButton: "btn btn-success ml-1",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    // Lógica para sincronizar desde Conexión 1 a Conexión 2
                    console.log('Sincronizando desde Conexión 1 a Conexión 2');

                    let items = []
                    let newItems = this.onlySelected ? this.sortedArticulosCombinados.filter(a => a.selected === true) : this.sortedArticulosCombinados;
                    newItems.forEach(item => {
                        // Convertir a número si es posible, o asignar 0 si no es un número válido
                        let articuloId = parseInt(item.articulo_Id2) || 0;
                        let precio = parseFloat(item.precio) || 0
                        if (articuloId > 0 && precio > 0)
                        {
                            items.push({
                                precio_Articulo_Id: -1,
                                articulo_Id: articuloId,
                                precio_Empresa_Id: this.filter2.precioEmpresaId,
                                precio: precio,
                                moneda_Id: 0,
                                margen: 0,
                                markup: 0,
                                fecha_Hora_Ult_Modif: "2024-09-12T19:02:09.181Z",
                                empresa_Id: this.filter2.idEmpresa,
                            })
                        }
                    })

                    try {   
                        
                        const { data } = await ArticlesResource.updateOrCreatePricesArticles(items);
                        if (data.isSuccesful) {
                            this.success(data.message)
                            this.$swal.close();  // Cerrar el modal manualmente tras éxito
                            await this.loadArticles()
                        } else {
                            this.$swal.showValidationMessage(data.message)
                            return false; // Prevenir que el modal se cierre en caso de error
                        }
                    }catch(e) {   
                        this.loading = false                     
                        this.handleResponseErrors(e)
                        this.$swal.showValidationMessage(`
                            Request failed: ${e}
                        `);
                        return false; // Prevenir que el modal se cierre en caso de error
                    }
                },
                preDeny: async () => {
                    // Lógica para sincronizar desde Conexión 2 a Conexión 1
                    console.log('Sincronizando desde Conexión 2 a Conexión 1');

                    let items = []
                    let newItems = this.onlySelected ? this.sortedArticulosCombinados.filter(a => a.selected === true) : this.sortedArticulosCombinados;
                    newItems.forEach(item => {

                        // Convertir a número si es posible, o asignar 0 si no es un número válido
                        let articuloId = parseInt(item.articulo_Id) || 0;
                        let precio = parseFloat(item.precio2) || 0
                        if (articuloId > 0 && precio > 0) {
                            items.push({
                                precio_Articulo_Id: -1,
                                articulo_Id: articuloId,
                                precio_Empresa_Id: this.filter.precioEmpresaId,
                                precio: precio,
                                moneda_Id: 0,
                                margen: 0,
                                markup: 0,
                                fecha_Hora_Ult_Modif: "2024-09-12T19:02:09.181Z",
                                empresa_Id: this.filter.idEmpresa,
                            })
                        }
                    })

                    try {   
                        
                        const { data } = await ArticlesResource.updateOrCreatePricesArticles(items);
                        if (data.isSuccesful) {
                            this.success(data.message)
                            this.$swal.close();  // Cerrar el modal manualmente tras éxito
                            await this.loadArticles()
                        } else {
                            this.$swal.showValidationMessage(data.message)
                            return false; // Prevenir que el modal se cierre en caso de error
                        }
                    }catch(e) {   
                        this.loading = false                     
                        this.handleResponseErrors(e)
                        this.$swal.showValidationMessage(`Request failed: ${e}`);
                        return false; // Prevenir que el modal se cierre en caso de error
                    }
                },
                didOpen: () => {
                    // Deshabilitar el botón de deny
                    const denyButton = this.$swal.getDenyButton();
                    denyButton.disabled = true;
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            });

        },
        selectPriceList(evt) {
            this.articles = []
            this.articles2 = []
            this.pageNumber = 1
            this.selectedRowIndex = -1
        },
        selectPriceList2() {
            this.articles2 = []
            this.articles = []
            this.pageNumber = 1
            this.selectedRowIndex = -1
        },
        async selectConnection(evt) {
            const idEmpresa = evt
            this.pricesList = []
            this.pricesList2 = []
            this.articles = []
            this.articles2 = []
            this.pageNumber = 1
            this.selectedRowIndex = -1
            this.filter.precioEmpresaId = null
            this.filter2.idEmpresa = null
            this.filter2.precioEmpresaId = null
            if (idEmpresa) {
                await this.getPricesList(idEmpresa)
            }
        },
        async selectConnection2(evt) {
            this.articles2 = []
            this.pricesList2 = []
            this.selectedRowIndex = -1
            const idEmpresa = evt
            if (idEmpresa) {
                await this.getPricesList2(idEmpresa)
            }
        },
        async getPricesList(idEmpresa) {
            this.pricesList = []
            try {
                this.loading = true
                const { data } = await PricesArticlesResource.getAllPricesList(idEmpresa)
                this.loading = false   
                if (data.isSuccesful) {             
                    this.pricesList = data.data
                } else {
                    this.danger(data.message)
                }
            }catch(error) {
                this.loading = false
                console.error("Error loading prices list", error)
                this.handleErrorResponse(error)
            }
        },
        async getPricesList2(idEmpresa) {
            this.pricesList2 = []
            try {
                this.loading = true
                const { data } = await PricesArticlesResource.getAllPricesList(idEmpresa)
                this.loading = false  
                if (data.isSuccesful) {
                    this.pricesList2 = data.data
                } else {
                    this.danger(data.message)
                }
                
            }catch(error) {
                this.loading = false
                console.error("Error loading prices list", error)
                this.handleErrorResponse(error)
            }
        },
        async loadArticles() {
            this.articles = []
            this.articles2 = []
            this.pageNumber = 1
            this.selectedRowIndex = -1
            this.relation = {}
            await this.getArticles();
            await this.getArticles2();
        },
        async getArticles() {
            this.selectedRowIndex = -1
            this.articles = []
            this.relation = {}
            const query = {
                ...this.filter,
                search: this.searchQuery,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                onlyRelationated: this.onlyRelationated,
            }

            try {
                this.loading = true
                const { data } = await ArticlesResource.getAllForSettingRelations(query)
                this.loading = false
                if (data.data.length === 0) {
                    this.endOfData = true
                    return
                } else {
                    this.articles.push(...data.data.map(a => {
                        return {
                            ...a,
                            rel_Clave_Id: a.rel_Clave_Id ? a.rel_Clave_Id : '',
                            rel_Clave_Id_Matriz: a.rel_Clave_Id_Matriz ? a.rel_Clave_Id_Matriz : '',
                            rel_Clave_Id_Concretos: a.rel_Clave_Id_Concretos ? a.rel_Clave_Id_Concretos : '',
                            rel_Clave_Id_Orlo: a.rel_Clave_Id_Orlo ? a.rel_Clave_Id_Orlo : ''
                        }
                    }));
                    this.pageNumber += 1;
                }
            }catch(error) {
                this.loading = false
                console.error("Error loading articles", error)
                this.handleErrorResponse(error)
            }
        },
        async getArticles2() {
            this.selectedRowIndex = -1
            this.articles2 = []
            this.relation = {}
            // this.pageNumber = 1
            const query = {
                ...this.filter2,
                search: this.searchQuery,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                onlyRelationated: this.onlyRelationated,
            }

            try {
                this.loading = true
                const { data } = await ArticlesResource.getAllForSettingRelations(query)
                this.loading = false
                if (data.data.length === 0) {
                    this.endOfData = true
                    return
                } else {
                    this.articles2.push(...data.data.map(a => {
                        return {
                            ...a,
                            rel_Clave_Id: a.rel_Clave_Id ? a.rel_Clave_Id : '',
                            rel_Clave_Id_Matriz: a.rel_Clave_Id_Matriz ? a.rel_Clave_Id_Matriz : '',
                            rel_Clave_Id_Concretos: a.rel_Clave_Id_Concretos ? a.rel_Clave_Id_Concretos : '',
                            rel_Clave_Id_Orlo: a.rel_Clave_Id_Orlo ? a.rel_Clave_Id_Orlo : ''
                        }
                    }));
                    this.pageNumber += 1;
                }
            }catch(error) {
                this.loading = false
                console.error("Error loading articles", error)
                this.handleErrorResponse(error)
            }
        },
        async sort(key) {
            this.loading = true; // Mostrar el spinner de carga

            this.relation = {}
            this.selectedRowIndex = -1
            
            // Esperar a que la UI actualice el estado del spinner
            await this.$nextTick();
            this.sortOrder = 1;

            if (this.sortKey === key) {
                this.sortOrder = -this.sortOrder; // Cambiar la dirección del orden
            } else {
                this.sortKey = key; // Ordenar por una nueva columna
            }

            // Simular un retraso para la carga, puede eliminarse en un caso real
            await new Promise(resolve => setTimeout(resolve, 500));

            this.loading = false; // Ocultar el spinner
        },
        loadItems() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = this.currentPage * this.itemsPerPage;
            this.visibleItems = this.sortedArticulosCombinados.slice(0, end);

            // this.visibleItems = this.sortedArticulosCombinados.slice(0, end).filter(item => {
            //     // Comprobar si las claves relacionadas son diferentes o si son nulas/vacías
            //     const claveDiferente = (item.rel_Clave_Id !== item.rel_Clave_Id2) || (!item.rel_Clave_Id && !item.rel_Clave_Id2);
                
            //     // Convertir los precios a números para evitar problemas de comparación de strings
            //     const precio1 = parseFloat(item.precio);
            //     const precio2 = parseFloat(item.precio2);

            //     // Comprobar si los precios son diferentes, si son nulos, vacíos o 0
            //     const precioDiferente = (isNaN(precio1) || isNaN(precio2) || precio1 !== precio2 || precio1 === 0 || precio2 === 0);

            //     // Devolver verdadero si alguna de las condiciones es verdadera
            //     return claveDiferente || precioDiferente;
            // });
        }, 
        handleScroll() {
            const tableContainer = this.$refs.tableContainer;
            const bottomOfTable = tableContainer.scrollTop + tableContainer.clientHeight >= tableContainer.scrollHeight - 10;
            if (bottomOfTable && !this.loading) {
                this.currentPage++;
                this.loadItems();
            }
        },
        selectRow(item, index) {
            this.relacion = {}
            this.selectedRowIndex = index
            this.relation = item
            // if (item && item.rel_Clave_Id && item.rel_Clave_Id != "" && item.rel_Clave_Id != 0 && item.rel_Clave_Id != null && item.rel_Clave_Id != -1) {
            //     this.isLinked = true
            // } else {
            //     this.isLinked = false
            // }
        },
        isSelected(index) {
            // Determina si la fila en el índice dado está seleccionada
            return this.visibleItems[index].selected || index === this.selectedRowIndex;
            // return index === this.selectedRowIndex
            
        },
    },
    watch: {
        onlyPriceDifference(value) {
            this.articles = []
            this.articles2 = []
            this.selectedRowIndex = -1
            this.relation = {}
        },
        onlyRelationated(value) {
            // if (!value) {
                this.articles = []
                this.articles2 = []
                this.selectedRowIndex = -1
                this.relation = {}
            // }
        },
        sortedArticulosCombinados() {
            this.loadItems();
        },
    }
}
</script>

<!-- CSS personalizado -->
<style scoped>
    .td-clave-articulo {
        width: 120px; /* Ajusta el ancho de las columnas según sea necesario */
        white-space: wrap; /* Evita que el texto se envuelva en estas columnas */
    }

    .td-articulo {
        width: 200px; /* Ajusta el ancho de la columna Articulo según sea necesario */
    }

    /* Asegúrate de que el texto se envuelva en la columna Articulo */
    .text-wrap {
        word-wrap: break-word; /* Habilita el ajuste de texto en esta columna */
    }

    .table th, .table td {
        text-align: left; /* Alinea el texto a la izquierda */
    }

    .selected-row {
        background-color: #007bff; /* Color de fondo para la fila seleccionada */
        color: white; /* Color de texto para la fila seleccionada */
    }
</style>
<style>
.b-table-sticky-header {
    overflow-y: auto;
    min-height: 400px;
}

.table-wrapper {
  height: 600px; /* Ajusta la altura según tus necesidades */
  overflow-y: auto;
}

</style>